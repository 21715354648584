import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const StorybookEmbed = makeShortcode("StorybookEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Installation`}</h2>
    <p>{`To get started using Primer Primitives, install the package with your package manager of choice:`}</p>
    <p>{`npm:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @primer/primitives
`}</code></pre>
    <p>{`yarn:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add @primer/primitives
`}</code></pre>
    <h2>{`Usage`}</h2>
    <p>{`Primer Primitive design tokens are available to consume as CSS variables. Import the desired token files into the root of your project.`}</p>
    <p>{`For sizing, spacing and typography tokens, import the following files:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@import '@primer/primitives/dist/css/base/size/size.css';
@import '@primer/primitives/dist/css/base/typography/typography.css';
@import '@primer/primitives/dist/css/functional/size/border.css';
@import '@primer/primitives/dist/css/functional/size/breakpoints.css';
@import '@primer/primitives/dist/css/functional/size/size-coarse.css';
@import '@primer/primitives/dist/css/functional/size/size-fine.css';
@import '@primer/primitives/dist/css/functional/size/size.css';
@import '@primer/primitives/dist/css/functional/size/viewport.css';
@import '@primer/primitives/dist/css/functional/typography/typography.css';
`}</code></pre>
    <p>{`Color tokens are grouped by individual theme files:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@import '@primer/primitives/dist/css/functional/themes/light.css';
@import '@primer/primitives/dist/css/functional/themes/light-tritanopia.css';
@import '@primer/primitives/dist/css/functional/themes/light-high-contrast.css';
@import '@primer/primitives/dist/css/functional/themes/light-colorblind.css';
@import '@primer/primitives/dist/css/functional/themes/dark.css';
@import '@primer/primitives/dist/css/functional/themes/dark-colorblind.css';
@import '@primer/primitives/dist/css/functional/themes/dark-dimmed.css';
@import '@primer/primitives/dist/css/functional/themes/dark-high-contrast.css';
@import '@primer/primitives/dist/css/functional/themes/dark-tritanopia.css';
`}</code></pre>
    <p>{`Utilizing CSS variables in SCSS or CSS files:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.Text {
  color: var(--fgColor-default);
}
`}</code></pre>
    <p>{`Utilizing CSS variables in React with Primer React (styled components):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Text sx={{color: 'var(--fgColor-default)'}}></Text>
`}</code></pre>
    <p><strong parentName="p">{`Note:`}</strong>{` `}<a parentName="p" {...{
        "href": "../../guides/react/theme-reference"
      }}>{`legacy variables`}</a>{` in Primer React from the `}<inlineCode parentName="p">{`theme`}</inlineCode>{` object all resolve to CSS variables under the hood. While the new naming convention is not available as a JS object, you can continue using the legacy variables in your React components or switch to using CSS variables directly (recommended).`}</p>
    <h2>{`Theming`}</h2>
    <p>{`Primer color design tokens are made available within data-attribute selectors on the `}<inlineCode parentName="p">{`body`}</inlineCode>{` tag or other high level dom element. There are three distinct data-attributes used to handle theming: `}<inlineCode parentName="p">{`data-color-mode`}</inlineCode>{`, `}<inlineCode parentName="p">{`data-light-theme`}</inlineCode>{`, and `}<inlineCode parentName="p">{`data-dark-theme`}</inlineCode>{`.`}</p>
    <h3>{`Color mode`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`data-color-mode`}</inlineCode>{` attribute is used to set the color mode of the theme. The value of the attribute should be either `}<inlineCode parentName="p">{`auto`}</inlineCode>{`, `}<inlineCode parentName="p">{`light`}</inlineCode>{`, or `}<inlineCode parentName="p">{`dark`}</inlineCode>{`. When set to `}<inlineCode parentName="p">{`auto`}</inlineCode>{`, the theme will automatically switch between light and dark based on the user's system preferences.`}</p>
    <h3>{`Color theme`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`data-light-theme`}</inlineCode>{` and `}<inlineCode parentName="p">{`data-dark-theme`}</inlineCode>{` attributes are used to set the theme. The value of the attribute should be the name of the theme file, replacing dashes `}<inlineCode parentName="p">{`-`}</inlineCode>{` with underscore `}<inlineCode parentName="p">{`_`}</inlineCode>{`. For example, to use the light theme, set the `}<inlineCode parentName="p">{`data-light-theme`}</inlineCode>{` attribute to `}<inlineCode parentName="p">{`light`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<body data-color-mode="light" data-light-theme="light" data-dark-theme="dark">
`}</code></pre>
    <p>{`To see theming in action, toggle between the theme in the following example:`}</p>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook/" stories={[{
      id: 'color-functional-roles--neutral'
    }]} height="480" mdxType="StorybookEmbed" />
    <h3>{`Available themes`}</h3>
    <ul>
      <li parentName="ul">{`light: `}<inlineCode parentName="li">{`light`}</inlineCode></li>
      <li parentName="ul">{`light-tritanopia: `}<inlineCode parentName="li">{`light_tritanopia`}</inlineCode></li>
      <li parentName="ul">{`light-high-contrast: `}<inlineCode parentName="li">{`light_high_contrast`}</inlineCode></li>
      <li parentName="ul">{`light-colorblind: `}<inlineCode parentName="li">{`light_colorblind`}</inlineCode></li>
      <li parentName="ul">{`dark: `}<inlineCode parentName="li">{`dark`}</inlineCode></li>
      <li parentName="ul">{`dark-colorblind: `}<inlineCode parentName="li">{`dark_colorblind`}</inlineCode></li>
      <li parentName="ul">{`dark-dimmed: `}<inlineCode parentName="li">{`dark_dimmed`}</inlineCode></li>
      <li parentName="ul">{`dark-high-contrast: `}<inlineCode parentName="li">{`dark_high_contrast`}</inlineCode></li>
      <li parentName="ul">{`dark-tritanopia: `}<inlineCode parentName="li">{`dark_tritanopia`}</inlineCode></li>
    </ul>
    <h2>{`CSS variables`}</h2>
    <p>{`To see all available tokens, reference the following guides:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/primitives/colors"
        }}>{`Colors`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/primitives/spacing"
        }}>{`Spacing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/primitives/typography"
        }}>{`Typography`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      